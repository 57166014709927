.globalFilters {
  display: flex;
  .filter {
    margin-right: 20px;
    label {
      display: block;
      color: #585869;
      font-size: 13px;
      line-height: 12px;
      margin-bottom: 12px;
    }
  }
}
