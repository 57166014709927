.addShiftHeader {
  display: flex;
  align-items: top;
  justify-content: space-between;
  padding-right: 30px;
  .col {
    display: flex;
    align-items: center;
    .profileImg {
      img {
        width: 40px;
        margin-right: 10px;
        border-radius: 50%;
        height: 40px;
        object-fit: cover;
        display: block;
      }
      .initials {
        background: #3399cc;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        letter-spacing: 0.03em;
        margin-right: 10px;
      }
    }
    .info {
      .name {
        font-weight: 300;
        color: #000a1d;
        font-size: 13px;
      }

      .date {
        font-size: 14px;
        font-weight: bold;
      }
    }
    &.repeat {
      align-items: start;
    }
  }
}
