.shift-scheduler {
  .scheduler-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .selectDate {
      display: flex;
      border: 1px solid #e0e0e0;
      button {
        background: white;
        border: none;
        border-right: 1px solid #e0e0e0;
        padding: 10px 5px;
        height: 40px;
        display: flex;
        align-items: center;
        cursor: pointer;

        &.nextBtn {
          border: 0;
          border-left: 1px solid #e0e0e0;
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    h2 {
      margin: 0;
      background: white;
      padding: 0 10px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      min-width: 180px;
      justify-content: center;
    }
  }

  .scheduler-grid {
    display: block; // Allows the grid to scroll horizontally
    overflow-x: auto;

    .grid-row {
      display: flex;
      border-bottom: 1px solid #e0e0e0;
      &.grid-header {
        border-top: 1px solid #e0e0e0;
      }
      .grid-cell {
        padding: 0.5rem;
        text-align: center;
        // border: 1px solid #e0e0e0;
        flex: 0 0 100px; // Fixed width for horizontal scrolling
        min-height: 50px;
        border-right: 0;
        border-bottom: 0;
        border-right: 1px solid #e0e0e0;
        background: white;
        .shift {
          border-radius: 5px;
          min-height: 60px;
          text-align: left;

          &.month {
            font-size: 11px;
            max-width: 35px;
          }
        }
        &.employee-header {
          font-weight: bold;
          background-color: #f4f4f4;
          border-left: 1px solid #e0e0e0;
        }

        &.date-header {
          font-weight: bold;
          flex: 1.2 1;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-flow: wrap;
          div {
            width: 100%;
          }
        }

        &.employee-name {
          font-weight: bold;
          background-color: #fff;
          border: none;
          border-right: 1px solid #e0e0e0;
          border-left: 1px solid #e0e0e0;
        }

        &.shift-data {
          color: #333;
          flex: 1.2 1;
          padding: 0;
          position: relative;
          &:hover {
            .addShift {
              display: flex;
            }
          }
          .addShift {
            position: absolute;
            bottom: 0;
            color: #3399cc;
            width: 100%;
            background: hsl(0deg 0% 100% / 89%);
            display: none;
            cursor: pointer;
            height: 100%;
            align-items: center;
            justify-content: center;
            bottom: 0;
            height: 30px;
            svg {
              stroke: #3399cc;
              width: 18px;
            }
          }
          .shiftDate {
            font-weight: bold;
            display: flex;
            align-items: center;
            height: 50px;
          }
        }
      }

      .dates-header {
        display: flex;
        overflow-x: auto;
        width: 100%;
      }

      .dates-row {
        display: flex;
        width: 100%;
      }
    }
  }
}

.viewType {
  button {
    background: white;
    margin-right: 5px;
    border: 1px solid #e0e0e0;
    padding: 3px 10px;
    height: 35px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    &.active {
      border-color: #585869;
    }
  }
  label {
    margin-bottom: 10px;
    display: block;
  }
}

.period {
  label {
    display: block;
    margin-bottom: 10px;
  }
}
